.as-class{
    margin-left: 0px;
    padding: 3px 0px;
    font-weight: 800;
}
.as-subject{
    margin-left: 20px;
    padding: 3px 0px;
    font-weight: 300;
}
.sch-board{
    float:left;
    margin-left: 20px;
    margin-bottom: 20px;
    padding: 3px 0px;
    font-weight: 300;
    clear: both;
}
legend {
    font-size: 19px !important;
}
.xpadl10{
    padding-left: 10px !important;
}
.xpadl20{
    padding-left: 20px !important;
}
.xpadl30{
    padding-left: 30px !important;
}

.xpadr10{
    padding-right: 10px !important;
}
.xpadr20{
    padding-right: 20px !important;
}
.xpadr30{
    padding-right: 30px !important;
}

.xmart0{
    margin-top: 0px !important;
}
.xmart10{
    margin-top: 10px !important;
}
.xmart20{
    margin-top: 20px !important;
}
.xmart30{
    margin-top: 30px !important;
}

.xmarb10{
    margin-bottom: 10px !important;
}
.xmarb20{
    margin-bottom: 20px !important;
}
.xmarb30{
    margin-bottom: 30px !important;
}

.xmarl10{
    margin-left: 10px !important;
}
.xmarl20{
    margin-left: 20px !important;
}
.xmarl30{
    margin-left: 30px !important;
}

.xmarr10{
    margin-right: 10px !important;
}
.xmarr20{
    margin-right: 20px !important;
}
.xmarr30{
    margin-right: 30px !important;
}
.catcard{
    background-color: #ccc !important;
}
.onclicklink{
    cursor: pointer !important;
}
.descp{
    word-break: break-all;
}
.mandate label::after { 
    content: "*"; 
    color: red;
    font-weight: bold;
    margin-left:5px;
  }
  .clsbg{
      background:#f0f0f0 !important;
  }